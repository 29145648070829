<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="now">你当前正在</div>
            <el-steps :active="active" align-center>
                <el-step title="选择提现金额"></el-step>
                <el-step title="确定提现账户"></el-step>
                <el-step title="填写账号信息"></el-step>
                <el-step title="填写支付密码"></el-step>
                <el-step title="提现成功"></el-step>
            </el-steps>
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>提现</span>
            </div>
            <!-- 选择提现金额 -->
            <div v-show="active == 0">
                <div class="hint">*提现金额</div>
                <div class="backJd">
                    <div class="moengt">
                        ￥ <input style="width: 50px;" v-model="form.money" type="number">
                    </div>
                    <div style="margin-left:20px">余额{{info.balance}}元，<span class="blue" @click="add(info.balance)">全部提出</span></div>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <div class="foot-btn">
                    <!-- <el-button class="orders" @click="pay">付款</el-button>
                        <div class="give-up">申诉</div> -->
                    <el-button class="orders" @click="pay(0)">下一步</el-button>
                </div>
            </div>
            <!-- 确定提现账户 -->
            <div v-show="active == 1">
                <div class="hint">*提现金额</div>
                <div class="backJd">
                    <div class="moengt red">
                        {{form.money}}元
                    </div>
                    <div style="margin-left:20px">手续费{{handList.paramValue}}%（￥{{form.money*(handList.paramValue/100)}}）</div>
                </div>
                <div class="hint">*提现账户</div>

                <div class="payment">
                    <el-radio v-model="radio" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width:21px;height:18px" src="../../assets/images/serve/yhk.png" alt="">
                                银行卡
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment">
                    <el-radio v-model="radio" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width:22px;height:22px" src="../../assets/images/serve/zfb.png" alt="">
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="orders" @click="pay(1)">下一步</el-button>
                </div>
            </div>
            <!-- 填写账户信息 -->
            <div v-show="active == 2">
                <!-- 银行卡提现 -->
                <div v-if="radio==1">
                    <div class="hint">*提现金额</div>
                    <div class="backJd">
                        <div class="moengt red">
                            {{form.money}} 元
                        </div>
                        <div style="margin-left:20px">手续费{{handList.paramValue}}%（￥{{form.money*(handList.paramValue/100)}}）</div>
                    </div>
                    <div class="hint">*持卡人</div>
                    <div class="code">
                        <input style="width:560px;margin-right:10px" v-model="form.userName" type="text">
                        <!-- <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button> -->
                    </div>
                    <div class="hint">*卡号</div>
                    <div class="code">
                        <input style="width:560px;margin-right:10px" v-model="form.account" type="text">
                        <!-- <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button> -->
                    </div>
                    <div class="hint">*开户行</div>
                    <div class="code">
                        <input style="width:560px;margin-right:10px" v-model="form.bankName" type="text">
                        <!-- <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button> -->
                    </div>
                    <div class="hint">*省级市</div>
                    <div class="code">
                        <el-select v-model="form.address" placeholder="地区">
                            <el-option v-for="item in proList" :key="item.id" :label="item.province" :value="item.provinceid"></el-option>
                        </el-select>
                        <div style="margin:0 20px">省</div>
                        <!-- <el-select v-if="form.address != ''" v-model="form.address" placeholder="地区">
                            <el-option v-for="item in cityList" :key="item.id" :label="item.city" :value="item.cityid"></el-option>
                        </el-select>
                        <div v-if="form.address != ''" style="margin:0 20px">市</div> -->
                    </div>
                    <div class="hint">*支行名称</div>
                    <div class="code">
                        <input style="width:560px;margin-right:10px" v-model="form.littleBank" type="text">
                        <!-- <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button> -->
                    </div>
                </div>
                <!-- 支付宝提现 -->
                <div v-if="radio==2">
                    <div class="hint">*提现金额</div>
                    <div class="backJd">
                        <div class="moengt red">
                            {{form.money}} 元
                        </div>
                        <div style="margin-left:20px">手续费{{handList.paramValue}}%（￥{{form.money*(handList.paramValue/100)}}）</div>
                    </div>
                    <div class="hint">*支付宝账户绑定手机号：</div>
                    <div class="code">
                        <input style="width:560px;margin-right:10px" v-model="form.account" type="text">
                        <!-- <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button> -->
                    </div>
                    <div class="hint">*支付宝实名认证姓名</div>
                    <div class="paypas">
                        <input style="width:560px;margin-right:10px" v-model="form.userName" type="text">
                        <!-- <input style="font-size:30px" v-for="(item,index) in captchas" type="text" :key="index" v-model="item.num" :id="'captcha'+index" @input="inputFinash(index)" @focus="adjust(index)" @keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" /> -->
                    </div>
                </div>
                <!-- <div class="hint">*图形验证码</div>
                <div class="code">
                    <input style="width:458px" v-model="form.phone" type="text">
                </div>
                <div class="verification">
                    <Code ref="ref_validateCode" @change="changeCode" />
                    <div class="text" @click="changeImg">看不清？<span>换张图</span></div>
                </div> -->
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="orders" @click="pay(2)">下一步</el-button>
                </div>

            </div>
            <!-- 填写支付密码 -->
            <div v-show="active == 3">
                <div class="hint">*提现金额</div>
                <div class="backJd">
                    <div class="moengt red">
                        {{ form.money}} 元
                    </div>
                    <div style="margin-left:20px">手续费{{handList.paramValue}}%（￥{{form.money*(handList.paramValue/100)}}）</div>
                </div>
                <div class="hint">*提现账户</div>
                <div class="payment" v-if="radio==1">
                    <el-radio v-model="radio" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width:21px;height:18px" src="../../assets/images/serve/yhk.png" alt="">
                                银行卡
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment" v-if="radio==2">
                    <el-radio v-model="radio" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width:22px;height:22px" src="../../assets/images/serve/zfb.png" alt="">
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="hint">*支付密码<span style="color:red">(平台钱包)</span></div>
                <div class="paypass">
                    <input v-for="(item,index) in captchas" type="password" :key="index" v-model="item.num" :id="'captch'+index" @input="inputFinasho(index)" @focus="adjusto(index)" @keydown="inputDirectiono(index)" class="captcha_input_box row-center" maxlength="1" />
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="orders" @click="pay(3)">下一步</el-button>
                </div>
            </div>
            <!-- 提现成功 -->
            <div v-show="active == 4">
                <!-- 审核中 -->
                <!-- <div class="no-win" v-if="examine==1"> -->
                <!-- 已申请提现<span class="red">{{ form.money}}元</span>，将在30分钟内到账 -->
                <!-- <img src="../../assets/images/serve/gzz.png" alt=""> -->
                <!-- 申请提现成功！审核中 -->
                <!-- </div> -->
                <!-- 审核成功 -->
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="">
                    申请提现成功！已申请提现<span class="red">{{ form.money}}元</span>，将在1-7个工作日内到账
                </div>
                <!-- 审核失败 -->
                <!-- <div class="no-win" v-if="examine==2"> -->
                <!-- <img src="../../assets/images/serve/close.png" alt=""> -->
                <!-- 申请提现成功！审核失败 -->
                <!-- </div> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Code from "../../components/code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean
    },

    data() {
        return {

            active: 0, //当前进度下标
            form: {
                money: '',
                account: '',
                userName: '',
                type: '',
                bankName: '',
                address: "",
                littleBank: "",
                payPsd: ""
            },
            radio: 1, //zhifu
            activeInput: 0,
            captchas: [ // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
            ],
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: '',
            isdisabledFn: false,
            checkCode: "", //图形码
            qrCodeShow: false,
            info: {},
            proList: [],
            cityList: [], //市
            examine: 3,
            handList: {}
        };
    },

    mounted() {
        this.getPro()
        // this.getCity()
        this.init()

    },

    methods: {
        // 回去市
        // getCity() {
        //     this.form.address = ''
        //     this.$util.post('/address/city', {
        //         fid: this.proList.provinceid
        //     }).then(res => {
        //         this.cityList = res.data
        //     })
        // },
        getPro() {
            this.$util.post('/address/provinces').then(res => {
                console.log(res);
                // this.getCity()
                this.proList = res.data
            })
        },
        //全部提出
        add(val) {
            this.form.money = val
        },
        pre() {
            this.active--
        },
        handleChange(value) {
            console.log(value);
        },

        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    // done();
                    this.$emit('close');
                    this.active = 0
                })
                .catch(_ => { });
        },
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if (this.form.phone == '') {
                return alert('请输入手机号')
            }
            this.countDownShow = true
            this.settime()
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false
                return false;
            } else {
                this.countDownShow = true
                this.isdisabledFn = true
                this.countDown = '重新发送' + "(" + this.count + ")"
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        compare() {
            if (this.form.smaCode.toUpperCase() === this.checkCode) {
                // if (this.active++ > 2) this.active = 0;
                this.obtain = 1
            } else {
                alert("比对失败,请重新输入");
                this.form.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                    this.form.payPsd = code
                    console.log(this.form.payPsd);
                }
            }
        },
        // 输入密码2
        adjusto(index) {
            let dom = document.getElementById("captch" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirectiono(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captch" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captch" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinasho(index) {
            console.log(this.captchas.length);

            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captch" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                    this.form.payPsd = code
                    console.log(this.form.payPsd.length);
                }
            }
        },
        // 付款
        pay(val) {
            if (val == 0) {
                if (this.form.money == '') {
                    this.$message.error("提现金额不能为空");
                } else if (this.form.money <= this.info.balance) {
                    this.active = ++val;
                } else {
                    this.$message.error("余额不足");
                }

            } else if (val == 1) {
                if (this.radio !== 1 && this.radio !== 2) {
                    this.$message.error("请先选择提现账户");
                    return
                } else {
                    this.form.type = this.radio
                    this.active = ++val;
                }

            } else if (val == 2) {
                if (this.radio == 1) {
                    if (this.form.userName == '') {
                        this.$message.error("请输入持卡人姓名");
                    } else if (this.form.account == '') {
                        this.$message.error("请输入银行卡号");
                    } else if (this.form.bankName == "") {
                        this.$message.error("请输入银行名称");
                    } else if (this.form.address == "") {
                        this.$message.error("请输入省级名称");
                    } else if (this.form.littleBank == "") {
                        this.$message.error("请输入分行名称");
                    } else {
                        this.active = ++val;
                    }
                }
                if (this.radio == 2) {
                    if (this.form.account == '') {
                        this.$message.error("请输入账号");
                    } else if (this.form.userName == '') {
                        this.$message.error("请输入用户名");
                    } else {
                        this.active = ++val;
                    }
                }

            } else if (val == 3) {
                if (this.form.payPsd !== "") {
                    this.$util
                        .post("/center/myOrder/outMoney", this.form)
                        .then((res) => {
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                            if (res.code == 200) {
                                console.log(res);
                                this.active = ++val;
                                // /center/myOrder/outList
                                this.$util
                                    .post("/center/myOrder/outList", {
                                        pageNo: 1,
                                        pageSize: 5
                                    })
                                    .then((res) => {
                                        if (res.code == 200) {
                                            console.log(res);
                                        }
                                    })
                                this.active + 1
                            }

                        });
                } else {
                    this.$message.error("请输入支付密码");
                }

            } else {
                console.log(this.active++);
                this.active + 1
            }


        },
        init() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
            });
            this.$util.post("/sys-param/list", {
                paramKey: "put_money_charge"
            }).then(res => {
                console.log(res);
                this.handList = res.data[0]
            })
        },
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.red {
    color: #e40012 !important;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypas {
    display: flex;
    align-items: center;
    input {
        height: 48px;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        // height: 48px;
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
        span {
            color: #e40012;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    padding: 13px 29px;
    background: #d1d1d1 !important;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.time-out {
    display: flex;
    align-items: center;
    margin: 100px 0;
    color: #ffcc00;
    font-size: 18px;
    justify-content: center;
    img {
        width: 38px;
        height: 32px;
        margin-right: 20px;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    cursor: pointer;
    font-size: 16px;
    color: #e40012;
}
.orders {
    // margin-top: 90px;
    // width: 247px;
    // height: 50px;
    padding: 13px 100px;
    background: #e40012;
    color: #fff;
    margin-left: 30px;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
}
/deep/.el-form-item__label {
    line-height: normal;
}
.l-hint {
    div {
        color: #2ea7e0;
        margin-bottom: 10px;
    }
    font-size: 14px;
    color: #222222;
}
.hui {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px;
}
.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: normal !important;
    div {
        padding: 6px 14px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        margin-right: 10px;
        // margin-bottom: 10px;
        cursor: pointer;
    }
    .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
    }
}
.moengt {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    color: #999999;
    padding: 15px 10px;
    width: 200px;
    input {
        flex: 1;
        padding-left: 10px;
        font-size: 16px;
        border: none;
        outline: none;
    }
}
.backJd {
    margin: 20px 0;
    color: #222;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.reason {
    display: flex;
    color: #222;
    align-items: center;
    margin-bottom: 20px;
    span {
        margin-right: 10px;
        color: #999999;
        font-size: 16px;
    }
}
.foot-content {
    margin: 20px 0;
    .cell {
        color: #222222;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
.qr-code {
    width: 257px;
    height: 257px;
    // margin: 40px auto;
    margin-left: 30px;
    background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.evaluation {
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #222;
}
.evaluation-info {
    margin: 30px 0 50px;
    font-size: 16px;
    .cell {
        display: flex;
        color: #222;
        align-items: center;
        margin-bottom: 30px;
        .title {
            width: 100px;
            color: #999;
            margin-right: 20px;
        }
    }
}
</style>