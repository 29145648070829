<template>
    <div>
        <div class="main-box">
            <div class="main">
                <div class="my-info" v-cloak>
                    ￥{{ balance ||0}}
                    <div class="money-btn">
                        <el-button class="cz-btn" @click="rechargeShow = true">充值</el-button>
                        <el-button class="cz-btn" @click="withdrawalShow = true">提现</el-button>
                    </div>
                </div>
                <div class="record">
                    <div class="record-title">提现记录</div>
                    <div class="cell-title">
                        <div>
                            <el-dropdown trigger="click" placement="bottom" @command="getTime">
                                <span class="el-dropdown-link">
                                    提现日期 <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in timeData" :command="item" :key="item.id">
                                        {{ item.title }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div>提现金额</div>
                        <div>
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    提现方式
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>

                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div>提现账户</div>
                        <div>服务费</div>
                        <div>提现状态</div>
                    </div>
                    <div class="cell-item" v-for="item in cellLists" :key="item.id">
                        <div style="width: 170px; text-align: left; padding-right: 15px">
                            {{ item.createTime }}
                        </div>
                        <div style="width: 100px; text-align: left; padding-right: 5px">
                            {{ item.money }}
                        </div>
                        <div style="width: 140px; text-align: right" v-if="item.type==1">银行卡</div>
                        <div style="width: 140px; text-align: right" v-if="item.type==2">支付宝</div>
                        <div style="width: 150px; text-align: right">{{ item.account }}</div>
                        <div style="width: 180px; text-align: right">{{ item.charge }}</div>
                        <div style="width: 180px; text-align: right; color:#FFC761 " v-if="item.state==1">审核中</div>
                        <div style="width: 180px; text-align: right; color:#4C7AF3 " v-if="item.state==2">提现失败</div>
                        <div style="width: 180px; text-align: right; color:#E40012" v-if="item.state==3">已提现</div>
                    </div>
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :page-size="needPage.pageSize" @current-change="changeNeed" :total="needPage.totalRows">
                        </el-pagination>
                        <div class="total">共{{ needPage.totalRows }}件/{{ needPage.totalPage }}页</div>
                        <div class="jump">
                            跳转到
                            <input type="number" v-model="needNum" />
                            页
                        </div>
                        <el-button style="background: #2ea7e0;color: #fff;width: 108px;height: 50px;font-size: 20px;" @click="goPage">前往</el-button>
                    </div>
                </div>
                <div class="record">
                    <div class="record-title">消费记录</div>
                    <div class="cell-title">
                        <div>
                            <el-dropdown trigger="click" placement="bottom" @command="getTimeList">
                                <span class="el-dropdown-link">
                                    消费日期<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in timeData" :command="item" :key="item.id">
                                        {{ item.title }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div>消费金额</div>
                        <div>
                            <el-dropdown trigger="click" placement="bottom" @command="getTypeList">
                                <span class="el-dropdown-link">
                                    消费类型<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in typeData" :command="item" :key="item.id">{{ item.title }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div>订单号</div>
                        <div>消费单号</div>
                    </div>
                    <div class="cell-item" v-for="item in cellList" :key="item.id">
                        <div style="width: 170px; text-align: left; padding-right: 15px">
                            {{ item.createTime }}
                        </div>
                        <div style="width: 100px; text-align: left; padding-right: 5px">
                            {{ item.num }}
                        </div>
                        <div style="width: 140px; text-align: right">{{ obj[item.type] }}</div>
                        <div style="width: 150px; text-align: right">{{ item.orderId }}</div>
                        <div style="width: 180px; text-align: right">{{ item.id }}</div>
                    </div>
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :page-size="consumPage.pageSize" @current-change="consumNeed" :total="consumPage.totalRows">
                        </el-pagination>
                        <div class="total">共{{ consumPage.totalRows }}件/{{ consumPage.totalPage }}页</div>
                        <div class="jump">
                            跳转到
                            <input type="number" v-model="consumNum" />
                            页
                        </div>
                        <el-button style="background: #2ea7e0;color: #fff;width: 108px;height: 50px;font-size: 20px;" @click="congoPage">前往</el-button>
                    </div>
                </div>
            </div>
        </div>
        <Recharge :dialogVisible="rechargeShow" @close="close" />
        <Withdrawal :dialogVisible="withdrawalShow" @close="close" />
    </div>
</template>

<script>
import Recharge from "../../components/manage/recharge.vue";
import Withdrawal from "../../components/manage/withdrawal.vue";
export default {
    components: { Recharge, Withdrawal },
    data() {
        return {
            form: {
                type: "",
                day: "",
                pageNo: 1,
                pageSize: 10,
            },
            rechargeShow: false, //充值弹出层
            withdrawalShow: false, //提现弹出层
            cellList: [], //交易明细列表
            timeData: [
                { id: 0, title: "3天内", day: 3 },
                { id: 1, title: "1周内", day: 7 },
                { id: 2, title: "1个月内", day: 30 },
                { id: 3, title: "3个月内", day: 90 },
                { id: 4, title: "半年内", day: 180 },
                { id: 5, title: "1年内", day: 365 },
                { id: 6, title: "3年内", day: 1095 },
            ],
            typeData: [
                { id: 0, title: "充值到账", type: 11 },
                { id: 1, title: "发布需求预付款", type: 2 },
                { id: 2, title: "投标", type: 3 },
                { id: 3, title: "发布需求首款", type: 4 },
                { id: 4, title: "发布需求尾款", type: 5 },
                { id: 5, title: "开店保证金", type: 6 },
                { id: 6, title: "技术支持费", type: 7 },
                { id: 7, title: "购买商品", type: 8 },
                { id: 8, title: "后台审核未通过", type: 9 },
                { id: 9, title: "中标需求确认收款", type: 14 },
                { id: 10, title: "店铺接单确认收款", type: 15 },
                { id: 11, title: "店铺审核未通过", type: 17 },
                { id: 12, title: "申诉退款处理", type: 18 },
                { id: 13, title: "预付款溢出回退", type: 19 },
                { id: 14, title: "申请提现", type: 31 },
                { id: 15, title: "拒绝提现回退", type: 32 },
                { id: 16, title: "数据广场查询", type: 20 },
                { id: 1010, title: "后台充值", type: 1010 },
            ],
            obj: {
                //state 所有的转态
                11: "充值到账",
                2: "发布需求预付款",
                3: "投标",
                4: "发布需求首款",
                5: "发布需求尾款",
                6: "开店保证金",
                7: "技术支持费",
                8: "购买商品",
                9: "后台充值",
                14: "中标需求确认收款",
                15: "店铺接单确认收款",
                17: "店铺审核未通过",
                18: "申诉退款处理",
                19: "预付款溢出回退",
                31: "申请提现",
                32: "拒绝提现回退",
                20: "数据广场查询",//新增
                1010: "后台充值"
            },
            info: {}, //我的个人信息
            cellLists: [],
            demandScreening: {
                day: 0,
                pageNo: 1,
                pageSize: 10,

            },//分页
            needPage: {}, //提现需求总页数
            needNum: "",//前往多少页
            consumPage: {},//消费记录总页数
            consumNum: '',//消费前往多扫页
            balance: 0
        };
    },
    beforeDestroy() {
        clearInterval(this.balanceTimer)
    },
    mounted() {
        this.getUserInfo()
        this.getInfo(); //执行个人信息
        this.allData();
    },
    methods: {
        congoPage() {
            this.form.pageNo = this.consumNum;
            this.allData();
        },
        //消费记录跳转页
        consumNeed(val) {
            this.form.pageNo = val
            this.allData();
        },
        changeNeed(num) {
            this.demandScreening.pageNo = num;
            this.allData();
        },
        goPage() {
            this.demandScreening.pageNo = this.needNum;
            this.allData();
        },
        //属性筛选
        getTypeList(item) {
            this.form.type = item.type
            this.allData()
        },
        //提现时间筛选
        getTime(val) {
            console.log(val);
            this.demandScreening.day = val.day
            this.allData();
        },
        //时间筛选
        getTimeList(item) {
            this.form.day = item.day;
            this.allData();
        },
        //定时器
        getUserInfo() {
            let _this = this
            //获取钱包地址接口
            //myWallet().then((res) => {
            //if (res.code == "200") {
            //清除实时更新钱包余额计时器
            clearInterval(_this.balanceTimer)
            //实时更新钱包余额计时器
            _this.balanceTimer = setInterval(async () => {
                //钱包列表单个余额更新
                await _this.getBalance()
            }, 3000);
        },
        //获取余额
        getBalance() {
            this.$util.post("/user/info").then((res) => {
                this.balance = res.data.balance;
            });
        },
        // 获取我的个人信息
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
            });
        },
        // 获取所有的明细列表
        allData() {
            this.$util.post("/user/record", this.form).then((res) => {
                console.log(res);
                this.cellList = res.data;
                this.consumPage = res.pager
            });
            this.$util.post("/center/myOrder/outList", this.demandScreening).then((res) => {
                console.log(res);
                this.cellLists = res.data;
                this.needPage = res.pager
            });

        },
        // 弹框关闭时触发
        close() {
            this.rechargeShow = false;
            this.withdrawalShow = false;
        },
    },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.pagination {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .jump {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin: 0 20px 0 40px;
        color: #2ea7e0;
        input {
            width: 40px;
            height: 40px;
            border: 1px solid #0f6c97;
            font-size: 14px;
            color: #2ea7e0;
            outline: none;
            margin: 0 10px;
            border-radius: 4px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
    .total {
        color: #2ea7e0;
        font-weight: bold;
        font-size: 16px;
    }
}

.main-box {
    background: #f6f6f6;
    padding: 20px 0 90px;
}
.main {
    width: 1200px;
    margin: 0 auto;
}
.my-info {
    background: url("../../assets/images/manage/wdqb-bg.png") round;
    padding: 100px 0 90px;
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 36px;
    color: #fff;
}
.money-btn {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    .cz-btn {
        width: 180px;
        height: 50px;
        background: #fff;
        color: #e40012;
        font-size: 18px;
        margin-right: 50px;
    }
}
.record {
    width: 1140px;
    background: #fff;
    padding: 30px;
    margin: 30px auto 0;
    /deep/.el-pager li {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px !important;
        border: 1px solid #2ea7e0;
        margin: 0 3px;
        color: #2ea7e0 !important;
    }
    /deep/.el-pagination .btn-next,
    /deep/.el-pagination .btn-prev {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px !important;
        // border: 1px solid #2ea7e0;
        background: transparent;
        margin: 0 3px;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
        color: #fff !important;
    }
    /deep/.el-pagination .btn-next .el-icon,
    /deep/.el-pagination .btn-prev .el-icon {
        font-size: 24px;
        color: #2ea7e0;
    }
    /deep/.el-pager li.active + li {
        border: 1px solid #2ea7e0;
    }
}
.record-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
    font-size: 22px;
    color: #666;
    margin-bottom: 30px;
}
.cell-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #222;
    text-align: center;
    /deep/.el-dropdown {
        font-size: 16px;
        color: #222;
    }
}
.cell-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #666;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #eeeeee;
}
</style>
